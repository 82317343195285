// 1000 to 1,000
const splitByThousandsWithCommas = (num?: string | number | null) => {
  if (num == null) {
    return '';
  }

  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export default splitByThousandsWithCommas;
