import { useEffect } from 'react';
// import qs from 'qs';
// import { Redirect } from 'react-router-dom';

// import { authSelectors } from 'ducks/auth';

// import useAppSelector from 'hooks/useAppSelector';
// import useQuery from 'hooks/useQuery';

import { /* authRedirects, */ platformRedirects, platformUrl } from 'utils/redirects';

const RequireAuthView = ({ children }: { children: JSX.Element | null }) => {
  // const query = useQuery();

  useEffect(() => {
    window.location.href = `${platformUrl}${platformRedirects.campaigns}`;
  }, []);

  // const isAuthorized = useAppSelector(authSelectors.isAuthorized);

  return null;

  // if (!isAuthorized) {
  //   return (
  //     <Redirect
  //       to={{
  //         pathname: authRedirects.auth,
  //         search: qs.stringify(query),
  //       }}
  //     />
  //   );
  // }

  // return children;
};

export default RequireAuthView;
