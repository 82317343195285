import splitByThousandsWithCommas from 'utils/formatters/splitByThounsandsWithCommas';

interface PaginationCounterParams {
  offset: number;
  limit: number;
  total: number;
  totalText?: string;
  items?: string;
  onlyRange?: boolean;
  alwaysShow?: boolean;
}

const PaginationCounter = ({
  offset,
  limit,
  total,
  totalText,
  items,
  alwaysShow = true,
}: PaginationCounterParams) => {
  if (total <= limit && !alwaysShow) {
    return null;
  }
  const start = !total ? offset : offset + 1;
  const end = offset + Math.min(limit, total - offset);

  const readableStart = splitByThousandsWithCommas(start);
  const readableEnd = splitByThousandsWithCommas(end);
  const readableTotal = totalText || splitByThousandsWithCommas(total);

  return (
    <>
      {readableStart} {start < end && `– ${readableEnd}`} of {readableTotal} {items}
    </>
  );
};

export default PaginationCounter;
