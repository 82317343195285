import { useCallback, useEffect, useRef } from 'react';
import { Banner, Modal } from '@shopify/polaris';

import AffiliatePreview from 'components/shop/forms/AffiliatePreview';

import { EditAffiliateParams, shopActions } from 'ducks/shop';

import useApi from 'hooks/useApi';

const RemoveAffiliateModal = ({
  onFetch,
  onClose,
  affiliate,
  isActive,
}: {
  onFetch(): void;
  onClose(): void;
  affiliate: EditAffiliateParams | null;
  isActive: boolean;
}) => {
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const onSuccess = useCallback(async () => {
    if (isMounted.current) {
      handleClose();
      onFetch();
    }
  }, [handleClose, onFetch]);

  const { pending, error, onRequest, onResetError } = useApi<undefined, EditAffiliateParams>({
    action: shopActions.affiliatesRemove(),
    onSuccess,
  });

  if (!affiliate) {
    return null;
  }

  return (
    <Modal
      large
      open={isActive}
      onClose={handleClose}
      title="Are you sure you want to delete this item?"
      primaryAction={{
        content: 'Remove',
        destructive: true,
        onAction: async () => {
          try {
            onRequest(affiliate);
          } catch (err) {
            console.error(err);
          }
        },
        loading: pending,
      }}
      secondaryActions={[
        {
          content: 'Cancel',
          onAction: async () => {
            handleClose();
          },
        },
      ]}
    >
      {!!error && (
        <Modal.Section>
          <Banner
            title="Something went wrong"
            status="critical"
            onDismiss={() => {
              if (error) {
                onResetError();
              }
            }}
          >
            {error}
          </Banner>
        </Modal.Section>
      )}
      <Modal.Section>
        <AffiliatePreview initialAffiliate={affiliate} previewOnly />
      </Modal.Section>
    </Modal>
  );
};

export default RemoveAffiliateModal;
