import { Switch, Route } from 'react-router-dom';

import AuthView from 'views/auth/AuthView';
import RedirectAuthView from 'views/auth/RedirectAuthView';
import RequireAuthView from 'views/auth/RequireAuthView';
import ShopView from 'views/shop/ShopView';

import CoreLayout from 'layouts/CoreLayout';

import StatusPage500 from 'components/errors/500';
import StatusPage404 from 'components/errors/404';

export default (
  <Switch>
    <Route exact path="/">
      <CoreLayout>
        <RequireAuthView>
          <ShopView />
        </RequireAuthView>
      </CoreLayout>
    </Route>

    {/* Auth */}
    <Route path="/auth">
      <CoreLayout>
        <AuthView />
      </CoreLayout>
    </Route>

    {/* Redirects */}
    <Route path="/redirect/auth">
      <CoreLayout>
        <RedirectAuthView />
      </CoreLayout>
    </Route>

    {/* Status pages */}
    <Route path="/status">
      <Route path="404">
        <CoreLayout>
          <StatusPage404 />
        </CoreLayout>
      </Route>
      <Route path="500">
        <CoreLayout>
          <StatusPage500 />
        </CoreLayout>
      </Route>
    </Route>
    <Route>
      <CoreLayout>
        <StatusPage404 />
      </CoreLayout>
    </Route>
  </Switch>
);
