import { useCallback, useEffect, useRef, useState } from 'react';
import { Banner, Modal } from '@shopify/polaris';

import AddAffiliateForm from 'components/shop/forms/AddAffiliateForm';
import AffiliatePreview from 'components/shop/forms/AffiliatePreview';

import { EditAffiliateParams, shopActions } from 'ducks/shop';

import useApi from 'hooks/useApi';

import sentry from 'utils/errors/sentry';

const EditAffiliateModal = ({
  onFetch,
  onClose,
  affiliate,
  isActive,
}: {
  onFetch(): void;
  onClose(): void;
  affiliate: EditAffiliateParams | null;
  isActive: boolean;
}) => {
  const [isEdit, setIsEdit] = useState(false);
  const [isSubmitActive, setIsSubmitActive] = useState(false);
  const [currentAffiliate, setCurrentAffiliate] = useState<EditAffiliateParams | null>(null);
  const refForm = useRef<HTMLFormElement>(null);

  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  const handleClose = useCallback(() => {
    onClose();
    setIsEdit(false);
    setCurrentAffiliate(null);
  }, [onClose]);

  const onSuccess = useCallback(async () => {
    if (isMounted.current) {
      handleClose();
      onFetch();
    }
  }, [handleClose, onFetch]);

  const { pending, error, onRequest, onResetError } = useApi<undefined, EditAffiliateParams>({
    action: shopActions.affiliatesEdit(),
    onSuccess,
  });

  if (!affiliate) {
    return null;
  }

  return (
    <Modal
      large
      open={isActive}
      onClose={handleClose}
      title="Edit Affiliate"
      primaryAction={{
        content: isEdit ? 'Save' : 'Edit',
        onAction: async () => {
          try {
            if (isEdit) {
              refForm.current?.submit();

              return;
            }

            setIsEdit(true);
          } catch (err) {
            if (isEdit) {
              sentry('AddAffiliateForm submission problem', { err, refForm });
            }
          }
        },
        disabled: !isSubmitActive,
        loading: pending,
      }}
      secondaryActions={[
        {
          content: isEdit ? 'Cancel' : 'Close',
          onAction: async () => {
            if (isEdit) {
              setIsEdit(false);

              return;
            }

            handleClose();
          },
        },
      ]}
    >
      {!!error && (
        <Modal.Section>
          <Banner
            title="Something went wrong"
            status="critical"
            onDismiss={() => {
              if (error) {
                onResetError();
              }
            }}
          >
            {error}
          </Banner>
        </Modal.Section>
      )}
      <Modal.Section>
        {!isEdit && (
          <AffiliatePreview
            initialAffiliate={affiliate}
            setIsSubmitActive={setIsSubmitActive}
            setAffiliate={setCurrentAffiliate}
          />
        )}
        {isEdit && currentAffiliate && (
          <AddAffiliateForm
            onEdit={onRequest}
            initialAffiliate={currentAffiliate}
            setIsSubmitActive={setIsSubmitActive}
            ref={refForm}
            mode="edit"
          />
        )}
      </Modal.Section>
    </Modal>
  );
};

export default EditAffiliateModal;
