import { useEffect, useState } from 'react';
import { isEqual } from 'lodash';
import {
  ResourceList,
  ResourceItem,
  TextStyle,
  Card,
  Banner,
  SkeletonBodyText,
  Scrollable,
} from '@shopify/polaris';

import Pagination, { QueryParams } from 'components/common/pagination/Pagination';
import AddAffiliateModal from 'components/shop/modals/AddAffiliateModal';
import EditAffiliateModal from 'components/shop/modals/EditAffiliateModal';
import ErrorBanner from 'components/common/ErrorBanner';
import RemoveAffiliateModal from 'components/shop/modals/RemoveAffiliateModal';

import { EditAffiliateParams, shopActions } from 'ducks/shop';

import useApi from 'hooks/useApi';
import usePrevious from 'hooks/usePrevious';

import { hasLength } from 'utils/checkers';

interface AffiliateResponse {
  affiliates: EditAffiliateParams[];
  page_info: { limit: number; offset: number; total: number };
}

const Wrapper = ({ children }: { children: JSX.Element | null }) => (
  <Card.Section>{children}</Card.Section>
);

const AFFILIATES_PER_PAGE = 25;

const INITIAL_QUERY = {
  limit: AFFILIATES_PER_PAGE,
  offset: 0,
};

const Affiliates = () => {
  const [query, setQuery] = useState(INITIAL_QUERY);
  const [isEditModalOpened, setIsEditModalOpened] = useState(false);
  const [isRemoveModalOpened, setIsRemoveModalOpened] = useState(false);
  const [editModalProps, setEditModalProps] = useState<EditAffiliateParams | null>(null);
  const [removeModalProps, setRemoveModalProps] = useState<EditAffiliateParams | null>(null);
  const { pending, error, data, onRequest, onResetError } = useApi<AffiliateResponse, QueryParams>({
    action: shopActions.affiliatesFetch(),
    storePrevData: true,
  });

  const prevQuery = usePrevious(query);
  useEffect(() => {
    if (!isEqual(prevQuery, query)) {
      onRequest(query);
    }
  }, [onRequest, prevQuery, query]);

  if (!data && pending) {
    return (
      <Wrapper>
        <SkeletonBodyText />
      </Wrapper>
    );
  }

  if (!data && error) {
    return (
      <Wrapper>
        <ErrorBanner error={error} />
      </Wrapper>
    );
  }

  if (!data) {
    return null;
  }

  const handleOpenEditModal = (affiliate: EditAffiliateParams) => {
    setEditModalProps(affiliate);
    setIsEditModalOpened(true);
  };

  const handleCloseEditModal = () => {
    setEditModalProps(null);
    setIsEditModalOpened(false);
  };

  const handleOpenRemoveModal = (affiliate: EditAffiliateParams) => {
    setRemoveModalProps(affiliate);
    setIsRemoveModalOpened(true);
  };

  const handleCloseRemoveModal = () => {
    setRemoveModalProps(null);
    setIsRemoveModalOpened(false);
  };

  const {
    affiliates,
    page_info: { total, offset, limit },
  } = data;

  return (
    <>
      <Card.Header title="Affiliates">
        <AddAffiliateModal onFetch={() => onRequest(INITIAL_QUERY)} />
      </Card.Header>

      {!!error && (
        <Card.Section>
          <Banner
            title="Something went wrong"
            status="critical"
            onDismiss={() => {
              if (error) {
                onResetError();
              }
            }}
          >
            {error}
          </Banner>
        </Card.Section>
      )}

      <Card.Section>
        <Scrollable shadow style={{ maxHeight: '430px' }} focusable>
          <ResourceList
            loading={pending}
            totalItemsCount={total}
            items={affiliates}
            emptyState="No affiliates yet"
            renderItem={item => {
              const { id, slug, first_name, last_name, email } = item;
              const name = `${first_name} ${last_name}`;
              // const media = <Avatar customer size="medium" name={name} source={avatar} />;

              return (
                <ResourceItem
                  id={id as unknown as 'number'}
                  // media={media}
                  accessibilityLabel={`View details for ${name}`}
                  onClick={handleOpenEditModal.bind(null, item)}
                  verticalAlignment="center"
                  shortcutActions={[
                    {
                      content: 'Remove',
                      onAction: async () => {
                        handleOpenRemoveModal(item);
                      },
                    },
                  ]}
                >
                  <h3>
                    <TextStyle variation="strong">{name}</TextStyle>
                  </h3>
                  <div>
                    {email} | {slug}
                  </div>
                </ResourceItem>
              );
            }}
          />
          <RemoveAffiliateModal
            onFetch={() => onRequest(INITIAL_QUERY)}
            isActive={isRemoveModalOpened}
            affiliate={removeModalProps}
            onClose={handleCloseRemoveModal}
          />
          <EditAffiliateModal
            onFetch={() => onRequest(INITIAL_QUERY)}
            isActive={isEditModalOpened}
            affiliate={editModalProps}
            onClose={handleCloseEditModal}
          />
        </Scrollable>
      </Card.Section>
      {hasLength(affiliates) && (
        <Card.Section>
          <Pagination
            query={query}
            setQuery={setQuery}
            total={total}
            limit={limit}
            offset={offset}
            pending={pending}
          />
        </Card.Section>
      )}
    </>
  );
};

export default Affiliates;
