import { useCallback } from 'react';

import { authSelectors } from 'ducks/auth';

import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import handleApiCall from 'hooks/utils/handleApiCall';

import { ActionParams } from 'types/actionTypes';
import { ApiErrorResponse, ApiSuccessResponse } from 'types/responseTypes';

const useApiDispatch = () => {
  const dispatch = useAppDispatch();
  const authToken = useAppSelector(authSelectors.getToken);

  return useCallback(
    (action: ActionParams): Promise<void | ApiSuccessResponse | ApiErrorResponse> => {
      if (!action.meta || !action.meta.apiCall) {
        throw new Error('Invalid action');
      }

      dispatch(action);

      return handleApiCall({
        action,
        dispatch,
        authToken,
      });
    },
    [authToken, dispatch]
  );
};

export default useApiDispatch;
