import { ReactNode } from 'react';

const formatNodeList = (nodes: ReactNode[], delimiter = ', ') => {
  if (!nodes.length) {
    return null;
  }

  return nodes.reduce((acc, cur) => [acc, delimiter, cur]);
};

export default formatNodeList;
