import { forwardRef, useEffect, useImperativeHandle } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Form, FormLayout } from '@shopify/polaris';

import EmailField, { EMAIL_REGEX_PATTERN } from 'components/common/forms/EmailField';
import TextField from 'components/common/forms/TextField';
import { UserParams } from 'components/shop/modals/AddAffiliateModal';

import { AffiliateParams, EditAffiliateParams } from 'ducks/shop';

import { REQUIRED_FIELD_MESSAGE } from 'constants/messages';

type FormParams = EditAffiliateParams | UserParams;

interface AddAffiliateFormParams {
  onAdd?(affiliate: AffiliateParams): void;
  onEdit?(affiliate: EditAffiliateParams): void;
  initialAffiliate: FormParams;
  setIsSubmitActive(isActive: boolean): void;
  mode?: 'create' | 'edit';
}

const AddAffiliateForm = forwardRef<any, AddAffiliateFormParams>(
  ({ onAdd, onEdit, initialAffiliate, setIsSubmitActive, mode = 'create' }, ref) => {
    const isEdit = mode === 'edit';
    const { first_name, last_name, email, slug, inf_user_id, id } = initialAffiliate;
    const formMethods = useForm<FormParams>({
      defaultValues: {
        first_name,
        last_name,
        email,
        slug,
        inf_user_id: isEdit ? inf_user_id : id,
        id,
      },
    });

    const { handleSubmit, formState, register, trigger } = formMethods;

    useEffect(() => {
      register('first_name', { required: REQUIRED_FIELD_MESSAGE });
      register('last_name', { required: REQUIRED_FIELD_MESSAGE });
      register('email', {
        required: REQUIRED_FIELD_MESSAGE,
        pattern: EMAIL_REGEX_PATTERN,
      });
      register('slug', { required: REQUIRED_FIELD_MESSAGE });
      register('id');
      register('inf_user_id');

      trigger();
    }, [register, trigger]);

    useEffect(() => {
      setIsSubmitActive(formState.isValid);
    }, [formState, setIsSubmitActive]);

    const getSubmitAction = (form: FormParams) => {
      if (isEdit && onEdit) {
        return onEdit(form as EditAffiliateParams);
      }

      if (!isEdit && onAdd) {
        return onAdd(form as AffiliateParams);
      }

      return null;
    };

    useImperativeHandle(ref, () => ({
      submit() {
        handleSubmit(getSubmitAction)();
      },
    }));

    return (
      <FormProvider {...formMethods}>
        <Form onSubmit={handleSubmit(getSubmitAction)}>
          <FormLayout>
            <FormLayout.Group>
              <TextField name="first_name" label="First Name" />
              <TextField name="last_name" label="Last Name" />
            </FormLayout.Group>
            <FormLayout.Group>
              <EmailField name="email" label="Email" />
              <TextField name="slug" label="Slug Name" disabled />
            </FormLayout.Group>
          </FormLayout>
        </Form>
      </FormProvider>
    );
  }
);

export default AddAffiliateForm;
