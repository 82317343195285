import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { Severity } from '@sentry/browser';

import useApiDispatch from 'hooks/useApiDispatch';

import getErrorMessage from 'utils/formatters/getErrorMessage';
import sentry from 'utils/errors/sentry';

import { ActionParams } from 'types/actionTypes';
import { ApiErrorResponse } from 'types/responseTypes';

type ApiParams<T> = {
  action: ActionParams;
  onSuccess?: (data: T) => Promise<void>;
  onError?: (error: string | ReactNode | null) => void;
  storePrevData?: boolean;
  fetchOnMount?: boolean;
};

export type ApiErrorType = boolean | string | ReactNode | null;

interface useApiReturnParams<T, P> {
  pending: boolean;
  error?: ApiErrorType;
  data: T | null;
  onRequest(params?: P | undefined, apiSignal?: AbortSignal | undefined): Promise<any>;
  onResetError(): void;
}

const useApi = <ReturnType, PayloadType = undefined>({
  onSuccess,
  onError,
  action,
  storePrevData,
  fetchOnMount,
}: ApiParams<ReturnType>): useApiReturnParams<ReturnType, PayloadType> => {
  const dispatch = useApiDispatch();
  const refAction = useRef(action);
  const [pending, setPending] = useState(false);
  const [error, setError] = useState<ApiErrorType>(null);
  const [data, setData] = useState<ReturnType | null>(null);

  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  });

  const memoizedOnSuccess = useCallback(
    async response => {
      onSuccess?.(response);
    },
    [onSuccess]
  );
  const memoizedOnError = useCallback(
    response => {
      onError?.(response);
    },
    [onError]
  );

  const onResetError = () => {
    setError(null);
  };

  const onRequest = useCallback(
    async (params?: PayloadType, apiSignal?: AbortSignal) => {
      setError(null);

      if (!storePrevData) {
        setData(null);
      }

      setPending(true);

      try {
        if (params) {
          refAction.current.payload = params;

          if (apiSignal) {
            refAction.current.meta.apiSignal = apiSignal;
          }
        }

        const response = await dispatch(refAction.current);

        if (isMounted.current) {
          const { payload } = response || {};

          setData(payload);

          if (memoizedOnSuccess) {
            await memoizedOnSuccess(payload);
          }

          setPending(false);

          return payload;
        }

        return null;
      } catch (err) {
        if (isMounted.current) {
          const response = err as ApiErrorResponse;
          const messages = response?.payload?.messages;

          if (!messages) {
            sentry('Wrong error response', response, Severity.Error);
          }

          setError(getErrorMessage(response?.payload?.messages));

          if (memoizedOnError) {
            memoizedOnError(response);
          }

          setPending(false);

          return response;
        }

        return null;
      }
    },
    [refAction, dispatch, memoizedOnSuccess, memoizedOnError, storePrevData]
  );

  useEffect(() => {
    if (fetchOnMount) {
      onRequest();
    }
  }, [fetchOnMount, onRequest]);

  return { pending, error, data, onRequest, onResetError };
};

export default useApi;
