import { toLower, upperFirst } from 'lodash';

const formatTitleCase = (value?: string | null): string | null | undefined => {
  if (typeof value !== 'string') {
    return value;
  }

  return upperFirst(toLower(value));
};

export default formatTitleCase;
