import { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Card, SkeletonBodyText } from '@shopify/polaris';
import { isEmpty, isEqual } from 'lodash';

import ErrorBanner from 'components/common/ErrorBanner';

import { authActions, AuthDataType, ShopifyCheckParams } from 'ducks/auth';
import { baseActions } from 'ducks/base';

import useApi from 'hooks/useApi';
import useQuery from 'hooks/useQuery';
import usePrevious from 'hooks/usePrevious';

import { platformRedirects, platformUrl, redirectRedirects } from 'utils/redirects';

const Wrapper = ({ children }: { children: JSX.Element | null }) => (
  <Card>
    <Card.Section>{children}</Card.Section>
  </Card>
);

const AuthView = () => {
  const query: ShopifyCheckParams = useQuery();
  const history = useHistory();

  useEffect(() => {
    window.location.href = `${platformUrl}${platformRedirects.campaigns}`;
  }, []);

  const { shop } = query;

  const onSuccessAuth = useCallback(async response => {
    const { url } = response;

    if (!url) {
      return;
    }

    window.location.href = url;
  }, []);

  const {
    pending: authPending,
    error: authError,
    onRequest: onAuth,
  } = useApi<{ error: boolean; url: string }>({
    action: authActions.authenticate({
      shop,
      redirect_url: `${window.location.origin}${redirectRedirects.auth}`,
    }),
    onSuccess: onSuccessAuth,
  });

  const onSuccessCheck = useCallback(async () => {
    history.push('/');
  }, [history]);

  const onErrorCheck = useCallback(() => {
    onAuth();
  }, [onAuth]);

  const {
    pending: checkPending,
    error: checkError,
    onRequest: onCheck,
  } = useApi<{ token: AuthDataType }>({
    action: authActions.check(query),
    onSuccess: onSuccessCheck,
    onError: onErrorCheck,
  });

  const onSuccessHealth = useCallback(
    async data => {
      const { message } = data || {};

      if (message === 'OK') {
        onCheck();
      }
    },
    [onCheck]
  );

  const {
    pending: healthPending,
    error: healthError,
    onRequest: onCheckHealth,
  } = useApi<{ message: string }>({
    action: baseActions.health(),
    onSuccess: onSuccessHealth,
  });

  const prevQuery = usePrevious(query);
  useEffect(() => {
    if (!isEmpty(query) && !isEqual(prevQuery, query)) {
      onCheckHealth();
    }
  }, [query, prevQuery, onCheckHealth]);

  if (isEmpty(query)) {
    return (
      <Wrapper>
        <ErrorBanner error="Get parameters are required" />
      </Wrapper>
    );
  }

  const pending = healthPending || checkPending || authPending;
  const error = healthError || checkError || authError;

  if (!pending && error) {
    return (
      <Wrapper>
        <ErrorBanner error={error} />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <SkeletonBodyText />
    </Wrapper>
  );
};

export default AuthView;
