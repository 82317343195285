import { current } from '@reduxjs/toolkit';
import { identity, pick } from 'lodash';

import { ApiErrorType } from 'hooks/useApi';

import { ActionParams } from 'types/actionTypes';
import { ApiErrorResponse, ApiSuccessResponse } from 'types/responseTypes';

export const HTTP_504_RETRY_INTERVAL = 3000;

export const isPending = (action: ActionParams) => !!action.meta && !!action.meta.apiCall;

export const resolved = (type: string) => `${type}_SUCCESS`;

export const rejected = (type: string) => `${type}_FAILED`;

interface BaseReducerParams<StateParams> {
  type: string;
  onInitial?: (state: StateParams, response: any) => StateParams;
  onSuccess: (state: StateParams, response: ApiSuccessResponse) => StateParams;
  onError: (state: StateParams, response: ApiErrorResponse) => StateParams;
}

export const getBaseReducer = <StateParams>({
  type,
  onInitial,
  onSuccess,
  onError,
}: BaseReducerParams<StateParams>): any => {
  const reducers = {
    ...(onInitial ? { [type]: onInitial } : {}),
    ...(onSuccess ? { [resolved(type)]: onSuccess } : {}),
    ...(onError ? { [rejected(type)]: onError } : {}),
  };

  return reducers;
};

type StateWithHelpers<State> = State & { pending?: boolean; error?: ApiErrorType };

type StateWithData<State> = StateWithHelpers<State> & { data?: any };

export const setPending = <State>(state: State): StateWithHelpers<State> => ({
  ...current(state),
  pending: true,
  error: null,
});

export const setError = <State>(
  state: State,
  { payload: { messages } }: ApiErrorResponse
): StateWithHelpers<State> => ({
  ...current(state),
  pending: false,
  error: messages || true,
});

export const setFlatErrorMessages = <State>(
  state: State,
  { payload: { messages } }: ApiErrorResponse
): State => ({
  ...current(state),
  ...messages,
});

const defaultSuccessState = {
  pending: false,
  error: null,
};

export const setData =
  (formatter = identity as any) =>
  <State>(
    state: StateWithData<State>,
    { payload, meta }: ApiSuccessResponse
  ): StateWithData<State> => ({
    ...current(state),
    data: formatter(payload, state.data, meta),
    ...defaultSuccessState,
  });

export const setFlatData =
  (formatter = identity as any) =>
  <State>(state: State, { payload, meta }: ApiSuccessResponse): State => ({
    ...current(state),
    ...formatter(payload, meta),
  });

export const pickData =
  (fields: any[]) =>
  <StateParams>(
    state: StateWithData<StateParams>,
    { payload }: ApiSuccessResponse
  ): StateWithData<StateParams> => {
    const currentState = current(state);

    return {
      ...currentState,
      data: {
        ...currentState.data,
        ...pick(payload, fields),
      },
      ...defaultSuccessState,
    };
  };

export const pickFlatData =
  (fields: any[]) =>
  <StateParams>(state: StateParams, { payload }: ApiSuccessResponse): StateParams => ({
    ...current(state),
    ...pick(payload, fields),
  });

// export const mapData =
//   (fields: any[]) =>
//   (state: StateWithData, { payload: { data } }: ApiSuccessResponse): StateWithData => {
//     const nextData = Object.keys(fields).reduce((acc, key) => {
//       acc[fields[key]] = data[key];
//       return acc;
//     }, {});

//     return {
//       ...state,
//       data: {
//         ...state.data,
//         ...nextData,
//       },
//       ...defaultSuccessState,
//     };
//   };

// export const mapFlatData =
//   (fields: any[]) =>
//   (state: RootState, { payload: { data } }: ApiSuccessResponse): RootState => {
//     const nextData = Object.keys(fields).reduce((acc, key) => {
//       acc[fields[key]] = data[key];
//       return acc;
//     }, {});

//     return {
//       ...state,
//       ...nextData,
//     };
//   };
