import { Banner } from '@shopify/polaris';

import { ApiErrorType } from 'hooks/useApi';

interface ErrorBannerParams {
  error: ApiErrorType;
}

const ErrorBanner = ({ error }: ErrorBannerParams) => (
  <Banner title="Something went wrong" status="critical">
    <p>{error}</p>
  </Banner>
);

export default ErrorBanner;
