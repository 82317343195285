export default class FetchError extends Error {
  response: Response | null;

  status: number | null;

  reason: Error | null;

  constructor(response: Response | null, reason: Error | null) {
    super(response?.statusText);

    this.response = response;
    this.status = response?.status || null;
    this.reason = reason;
  }
}
