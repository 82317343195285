import { useCallback, useState } from 'react';
import { FormLayout } from '@shopify/polaris';

import Discounts from 'components/shop/Discounts';
import StatelessComponent from 'components/common/StatelessComponent';

import { DiscountCodesParams, EditAffiliateParams, shopActions } from 'ducks/shop';

import getUserName from 'utils/formatters/getUserName';

export interface InfoParams {
  affiliate: EditAffiliateParams;
  discounts: DiscountCodesParams[];
}

interface AffiliatePreviewParams {
  initialAffiliate: EditAffiliateParams;
  setIsSubmitActive?(bool: boolean): void;
  setAffiliate?(affiliate: EditAffiliateParams): void;
  previewOnly?: boolean;
}

const AffiliatePreview = ({
  initialAffiliate,
  initialAffiliate: { id: affiliateId },
  setIsSubmitActive,
  setAffiliate,
  previewOnly,
}: AffiliatePreviewParams) => {
  const [currentAffiliate, setCurrentAffiliate] = useState<EditAffiliateParams>(initialAffiliate);
  const [discountCodes, setDiscountCodes] = useState<DiscountCodesParams[]>([]);

  const onSuccess = useCallback(
    async response => {
      const { affiliate, discounts } = response;

      setIsSubmitActive?.(true);
      setAffiliate?.(affiliate);
      setCurrentAffiliate(affiliate);
      setDiscountCodes(discounts);
    },
    [setAffiliate, setIsSubmitActive]
  );

  return (
    <StatelessComponent<InfoParams, { affiliateId: number }>
      action={shopActions.affiliatesGetInfo(affiliateId)}
      fetchOnMount
      onSuccess={onSuccess}
    >
      {({ data }) => {
        const { email, slug } = currentAffiliate || {};
        const name = getUserName(currentAffiliate);

        return (
          <FormLayout>
            <FormLayout.Group title="Name">{name}</FormLayout.Group>
            <FormLayout.Group title="Email">{email}</FormLayout.Group>
            <FormLayout.Group title="Slug">{slug}</FormLayout.Group>
            <FormLayout.Group title="Discounts">
              <Discounts
                withoutWrapper
                attachedDiscountCodes={discountCodes}
                attachDiscountToAffiliate={currentAffiliate}
                setCurrentAffiliate={setCurrentAffiliate}
                setDiscountCodes={setDiscountCodes}
                previewOnly={previewOnly}
              />
            </FormLayout.Group>
          </FormLayout>
        );
      }}
    </StatelessComponent>
  );
};

export default AffiliatePreview;
