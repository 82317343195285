import { createReducer } from '@reduxjs/toolkit';

import authApi from 'api/auth';
import { getBaseReducer, setData, setError, setPending } from 'api/utils/helpers';

import { ApiErrorType } from 'hooks/useApi';

import { RootState } from 'stores/types';

import { createConstants } from 'utils/helpers';
import { getItem } from 'utils/sessionStorage';

import { AUTH_PATH } from 'middleware/auth';

export const authConstants = createConstants('AUTH_CHECK', 'AUTH_AUTHENTICATE', 'AUTH_FINALIZE');

export interface ShopifyCheckParams {
  hmac: string;
  host: string;
  locale: string;
  new_design_language: boolean;
  session: string;
  shop: string;
  timestamp: string;
}

export interface ShopifyFinalizeParams {
  code: string;
  hmac: string;
  host: string;
  shop: string;
  state: string;
  timestamp: string;
}

export const authActions = {
  check: (props: ShopifyCheckParams) => ({
    type: authConstants.AUTH_CHECK,
    payload: props,
    meta: {
      apiCall: authApi.check,
    },
  }),
  authenticate: ({ shop, redirect_url }: { shop: string; redirect_url: string }) => ({
    type: authConstants.AUTH_AUTHENTICATE,
    payload: { shop, redirect_url },
    meta: {
      apiCall: authApi.authenticate,
    },
  }),
  finalize: (props: ShopifyFinalizeParams) => ({
    type: authConstants.AUTH_FINALIZE,
    payload: props,
    meta: {
      apiCall: authApi.finalize,
    },
  }),
};

export interface AuthParams {
  access_token: string;
  shop: {
    id: number;
    name: string;
    secret: string;
  };
  token_type: 'bearer';
}

export type AuthDataType = AuthParams | null;

interface AuthState {
  pending: boolean;
  error: ApiErrorType;
  data: AuthDataType | null;
}

const initialState: AuthState = {
  pending: false,
  error: null,
  data: getItem(AUTH_PATH) || null,
};

const getLocalState = (state: RootState): AuthState => state.auth;
const getAuthData = (state: RootState): AuthDataType => getLocalState(state).data || null;
const getToken = (state: RootState): string => getAuthData(state)?.access_token || '';
const isAuthorized = (state: RootState): boolean => Boolean(getToken(state));

export const authSelectors = {
  isAuthorized,
  getAuthData,
  getToken,
};

const authReducer = {
  auth: createReducer(initialState, {
    ...getBaseReducer<AuthState>({
      type: authConstants.AUTH_CHECK,
      onInitial: setPending,
      onSuccess: setData(),
      onError: setError,
    }),
    ...getBaseReducer<AuthState>({
      type: authConstants.AUTH_FINALIZE,
      onInitial: setPending,
      onSuccess: setData(),
      onError: setError,
    }),
  }),
};

export default authReducer;
