import { useCallback } from 'react';
import { SkeletonBodyText } from '@shopify/polaris';

import ErrorBanner from 'components/common/ErrorBanner';
import ContentWrapper from 'components/common/ContentWrapper';

import useApi, { ApiErrorType } from 'hooks/useApi';

import { ActionParams } from 'types/actionTypes';
import typedMemo from 'types/typedMemo';

interface childrenParams<T, P> {
  pending: boolean;
  error: ApiErrorType;
  data: T;
  onRequest(params?: P | undefined, apiSignal?: AbortSignal | undefined): Promise<any>;
}

interface StatelessComponentParams<T, P> {
  action: ActionParams;
  fetchOnMount?: boolean;
  onSuccess?: (data: T) => Promise<void>;
  children({ pending, error, data, onRequest }: childrenParams<T, P>): JSX.Element | null;
  statusWrapper?: any;
}

const StatelessComponent = <ApiReturnParams extends unknown, ApiPayloadParams = undefined>({
  action,
  fetchOnMount = false,
  onSuccess,
  children,
  statusWrapper: StatusWrapper,
}: StatelessComponentParams<ApiReturnParams, ApiPayloadParams>) => {
  const memoizedOnSuccess = useCallback(
    async response => {
      onSuccess?.(response);
    },
    [onSuccess]
  );

  const { pending, error, data, onRequest } = useApi<ApiReturnParams, ApiPayloadParams>({
    action,
    fetchOnMount,
    onSuccess: memoizedOnSuccess,
  });

  if (pending) {
    return (
      <ContentWrapper noWrap={!StatusWrapper} tagName={StatusWrapper}>
        <SkeletonBodyText />
      </ContentWrapper>
    );
  }

  if (error) {
    return (
      <ContentWrapper noWrap={!StatusWrapper} tagName={StatusWrapper}>
        <ErrorBanner error={error} />
      </ContentWrapper>
    );
  }

  if (!data) {
    return null;
  }

  return children({ pending, error, data, onRequest });
};

export default typedMemo(StatelessComponent);
