import { AnyAction, Dispatch, MiddlewareAPI } from '@reduxjs/toolkit';

import { setItem, removeItem } from 'utils/sessionStorage';

export const AUTH_PATH = 'auth';

const authMiddleware =
  ({ getState }: MiddlewareAPI<any>) =>
  (next: Dispatch<AnyAction>) =>
  (action: AnyAction) => {
    const { auth: prevAuthState } = getState();
    const processedAction = next(action);
    const { auth: nextAuthState } = getState();

    // Ignore when auth state was not changed
    if (nextAuthState === prevAuthState) {
      return processedAction;
    }

    const { data } = nextAuthState;

    if (data) {
      setItem(AUTH_PATH, data);

      return processedAction;
    }

    removeItem(AUTH_PATH);

    return processedAction;
  };

export default authMiddleware;
