import { Pagination as ShopifyPagination, Stack } from '@shopify/polaris';

import PaginationCounter from 'components/common/pagination/PaginationCounter';

export interface QueryParams {
  limit: number;
  offset: number;
}

interface PaginationParams {
  setQuery: (query: QueryParams) => void;
  query: QueryParams;
  limit: number;
  offset: number;
  total: number;
  pending?: boolean;
}

const Pagination = ({ setQuery, query, limit, offset, total, pending }: PaginationParams) => {
  const prevOffset = offset - limit;
  const prevDisabled = pending || prevOffset < 0;
  const nextOffset = offset + limit;
  const nextDisabled = pending || nextOffset > total;

  return (
    <Stack distribution="equalSpacing" alignment="center">
      <PaginationCounter offset={offset} limit={limit} total={total} />
      <ShopifyPagination
        hasPrevious={!prevDisabled}
        onPrevious={() => {
          setQuery({ ...query, offset: prevOffset });
        }}
        hasNext={!nextDisabled}
        onNext={() => {
          setQuery({ ...query, offset: nextOffset });
        }}
      />
    </Stack>
  );
};

export default Pagination;
