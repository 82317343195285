import { ApiErrorResponsePayloadParams } from 'types/responseTypes';

export default class ApiError extends Error {
  response: Response | null;

  responseJSON: ApiErrorResponsePayloadParams;

  status: number | null;

  constructor(response: Response | null, responseJSON: ApiErrorResponsePayloadParams) {
    super(`Api returned an error. ${JSON.stringify(responseJSON.messages)}`);
    this.response = response;
    this.responseJSON = responseJSON;
    this.status = response?.status || null;
  }
}
