import { Component, ErrorInfo } from 'react';
import { Severity } from '@sentry/browser';

import { __PROD__ } from 'constants/env';

import sentry from 'utils/errors/sentry';

interface Props {
  children: JSX.Element | null;
}

type ErrorInfoType = ErrorInfo & { message: string };

interface State {
  error: Error | null;
  info: ErrorInfoType | null;
}

class ErrorHandlingLayout extends Component<Props, State> {
  public state: State = {
    error: null,
    info: null,
  };

  public static getDerivedStateFromError(error: Error, errorInfo: ErrorInfoType): State {
    // Update state so the next render will show the fallback UI.
    return { error, info: errorInfo };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfoType) {
    sentry(errorInfo.message, error, Severity.Info);
    this.setState({ error, info: errorInfo });
  }

  public render() {
    const { children } = this.props;
    const { error, info } = this.state;

    if (!error) {
      return children;
    }

    return (
      <div className="page">
        <div className="page-inner">
          <div className="container container-errors">
            <div className="view-error-section">
              <div className="view-error-section__status __error-500" />
              <p className="view-error-section__text">Oops, something went wrong</p>
              {!__PROD__ && (
                <div>
                  <pre className="view-error-section__text c-error">{error.message}</pre>
                  <div
                    className="view-error-section__debug"
                    title="This will not be visible in production"
                  >
                    <pre>{error.stack}</pre>
                    {!!info && <pre>{info.componentStack}</pre>}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ErrorHandlingLayout;
