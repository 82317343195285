import baseApi from 'api/base';

import { createConstants } from 'utils/helpers';

export const baseConstants = createConstants('BASE_HEALTH');

export const baseActions = {
  health: () => ({
    type: baseConstants.BASE_HEALTH,
    meta: {
      apiCall: baseApi.health,
    },
  }),
};
