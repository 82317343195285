const packageJson = require('../../package.json');

// ------------------------------------
// Environment
// ------------------------------------

/* eslint-disable no-underscore-dangle */
export const __VERSION__ = JSON.stringify(packageJson.version);
export const __REVISION__ = process.env.REACT_APP_VERSION;
export const __ENV__ = process.env.NODE_ENV;
export const __DEV__ = process.env.NODE_ENV === 'development';
export const __TESTING__ = process.env.NODE_ENV === 'test';
export const __PROD__ = process.env.NODE_ENV === 'production';
/* eslint-enable */
