import { Dispatch } from 'react';
import { Severity } from '@sentry/browser';

import { HTTP_504_RETRY_INTERVAL, rejected, resolved } from 'api/utils/helpers';

import { __PROD__ } from 'constants/env';
import {
  FETCH_ERROR_MESSAGE,
  API_ERROR_OVERRIDE_MESSAGES,
  TYPE_ERROR_MESSAGE,
} from 'constants/messages';

import { timer } from 'utils/async';
import AbortError from 'utils/errors/AbortError';
import ApiError from 'utils/errors/ApiError';
import FetchError from 'utils/errors/FetchError';
import sentry from 'utils/errors/sentry';

import { ApiErrorResponse, ApiSuccessResponse } from 'types/responseTypes';
import { ActionParams } from 'types/actionTypes';

const lastRequestIds: { [k: string]: number } = {};

const handleApiCall = async ({
  action,
  dispatch,
  authToken,
}: {
  action: ActionParams;
  dispatch: Dispatch<any>;
  authToken?: string;
}): Promise<void | ApiSuccessResponse | ApiErrorResponse> => {
  const {
    type,
    payload,
    meta: { apiCall, apiSignal, apiOnlyLatest, ...otherMeta },
  } = action;

  if (!lastRequestIds[type]) {
    lastRequestIds[type] = 0;
  }

  lastRequestIds[type] += 1;
  const requestId = lastRequestIds[type];

  return apiCall({
    data: payload,
    apiSignal,
    authToken,
  })
    .then(result => {
      if (apiOnlyLatest && requestId !== lastRequestIds[type]) {
        throw new AbortError('the newer request is in progress');
      }

      return dispatch({
        type: resolved(type),
        payload: result,
        meta: {
          ...otherMeta,
          requestAction: action,
        },
      });
    })
    .catch(async (error: Error) => {
      if (apiOnlyLatest && requestId !== lastRequestIds[type]) {
        return undefined;
      }
      if (error instanceof FetchError) {
        if (error.status === 504) {
          sentry(
            `[Back-End] [504] Request for ${error.response?.url} has timed out`,
            { error, action },
            Severity.Warning
          );

          return timer(HTTP_504_RETRY_INTERVAL).then(() =>
            handleApiCall({
              action,
              dispatch,
              authToken,
            })
          );
        }

        if (error.status === 500) {
          sentry(`[Back-End] [500] Server FetchError at ${error.response?.url}`, { error, action });
        }

        const errorMessage = {
          id: (error.status && API_ERROR_OVERRIDE_MESSAGES[error.status]) || FETCH_ERROR_MESSAGE,
        };

        const errorPayload = {
          error: true,
          messages: errorMessage,
        };

        sentry('Log Error messages', errorPayload, Severity.Info);

        throw dispatch({
          type: rejected(type),
          error: true,
          payload: errorPayload,
          meta: {
            ...otherMeta,
            requestAction: action,
            apiCallError: error,
          },
        });
      }

      if (error instanceof ApiError) {
        // eslint-disable-next-line default-case
        switch (error.status) {
          case 401:
            break;
          case 404:
            break;
          case 500:
            // Server errors we can not handle
            sentry(`[Back-End] [500] Server error at ${error.response?.url}`, { error, action });

            break;
          case 502:
            sentry(`[Back-End] [502] Bad Gateway at ${error.response?.url}`, { error, action });
            break;
          case 503:
            sentry(`[Back-End] [503] Service Unavailable at ${error.response?.url}`, {
              error,
              action,
            });
            break;
          case 504:
            sentry(`[Back-End] [504] Gateway Timeout at ${error.response?.url}`, { error, action });
            break;
        }
        // Allow custom messages for specific http error codes
        const customMessage = error.status && API_ERROR_OVERRIDE_MESSAGES[error.status];
        const errorPayload = customMessage
          ? {
              error: true,
              messages: {
                id: customMessage,
              },
            }
          : error.responseJSON;
        sentry('Log Error messages', errorPayload, Severity.Info);

        throw dispatch({
          type: rejected(type),
          error: true,
          payload: errorPayload,
          meta: {
            ...otherMeta,
            requestAction: action,
            apiCallError: error,
          },
        });
      }

      if (error instanceof TypeError) {
        const { message } = error;

        sentry('Unhandled TypeError', { error, action }, Severity.Error);

        const errorPayload = {
          error: true,
          messages: {
            id: !__PROD__ ? message : TYPE_ERROR_MESSAGE,
          },
        };

        sentry('Log Error messages', errorPayload, Severity.Info);

        throw dispatch({
          type: rejected(type),
          error: true,
          payload: errorPayload,
          meta: {
            ...otherMeta,
            requestAction: action,
            apiCallError: error,
          },
        });
      }

      // Just re-throw the error, as this may indicate
      // any error in promise handling code
      // TODO: is there a better way? Dispatch an action with 'internal error' message?
      sentry('Log Error messages', error, Severity.Info);
      throw error;
    });
};

export default handleApiCall;
