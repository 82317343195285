import * as Sentry from '@sentry/browser';
import { Severity } from '@sentry/browser';

import { __PROD__ } from 'constants/env';

const sentry = (message: string, error: any, level: Severity = Severity.Critical) => {
  const method = __PROD__ ? level : 'warning';

  Sentry.withScope(scope => {
    scope.setExtra('error', error);
    scope.setLevel(level);
    Sentry.captureMessage(message);
  });

  // @ts-ignore
  if (window.Sentry) {
    // @ts-ignore
    window.Sentry[method](message, error);
  }
};

export default sentry;
