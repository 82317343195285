import { createReducer } from '@reduxjs/toolkit';

import shopApi from 'api/shop';

import { getBaseReducer, setData, setError } from 'api/utils/helpers';

import { ApiErrorType } from 'hooks/useApi';

import { createConstants } from 'utils/helpers';

export const shopConstants = createConstants(
  'SHOP_FETCH',

  // Affiliates
  'AFFILIATES_FETCH',
  'AFFILIATES_ADD',
  'AFFILIATES_EDIT',
  'AFFILIATES_REMOVE',
  'AFFILIATES_GET_USER',

  // Discounts
  'DISCOUNTS_FETCH',
  'DISCOUNTS_ATTACH',
  'DISCOUNTS_DETTACH'
);

export interface AffiliateParams {
  id?: number;
  inf_user_id: number;
  slug: string;
  first_name: string;
  last_name: string;
  email: string;
}

export interface EditAffiliateParams extends AffiliateParams {
  id: number;
}

export interface DiscountCodesParams {
  id?: number;
  shopify_discount_id: string;
  title: string;
  status: 'ACTIVE';
  summary: string;
  commission?: number;
}

export interface AttachAffiliateParams {
  affiliate: EditAffiliateParams;
  discount: DiscountCodesParams;
}

export const shopActions = {
  shop: () => ({
    type: shopConstants.SHOP_FETCH,
    meta: {
      apiCall: shopApi.shop,
    },
  }),

  // Affiliates
  affiliatesFetch: () => ({
    type: shopConstants.AFFILIATES_FETCH,
    meta: {
      apiCall: shopApi.affiliatesFetch,
    },
  }),
  affiliatesAdd: (props?: AffiliateParams) => ({
    type: shopConstants.AFFILIATES_ADD,
    payload: props,
    meta: {
      apiCall: shopApi.affiliatesAdd,
    },
  }),
  affiliatesEdit: (props?: EditAffiliateParams) => ({
    type: shopConstants.AFFILIATES_EDIT,
    payload: props,
    meta: {
      apiCall: shopApi.affiliatesEdit,
    },
  }),
  affiliatesRemove: (props?: EditAffiliateParams) => ({
    type: shopConstants.AFFILIATES_REMOVE,
    payload: props,
    meta: {
      apiCall: shopApi.affiliatesRemove,
    },
  }),
  affiliatesGetInfo: (userId: number) => ({
    type: shopConstants.AFFILIATES_GET_USER,
    payload: { userId },
    meta: {
      apiCall: shopApi.affiliatesGetInfo,
    },
  }),

  // Discounts
  discountsFetch: () => ({
    type: shopConstants.DISCOUNTS_FETCH,
    meta: {
      apiCall: shopApi.discountsFetch,
    },
  }),
  discountsAttach: (props?: AttachAffiliateParams) => ({
    type: shopConstants.DISCOUNTS_ATTACH,
    payload: props,
    meta: {
      apiCall: shopApi.discountsAttach,
    },
  }),
  discountsDetach: (props?: AttachAffiliateParams) => ({
    type: shopConstants.DISCOUNTS_DETTACH,
    payload: props,
    meta: {
      apiCall: shopApi.discountsDetach,
    },
  }),
};

export interface ShopParams {
  shop: {
    id: number;
    name: string;
    secret: string;
  };
  connections: {
    id: number;
    organization_id: number;
    organization_title: string;
  }[];
}

type ShopDataType = ShopParams | null;

interface ShopState {
  pending: boolean;
  error: ApiErrorType;
  data: ShopDataType;
}

const initialState: ShopState = {
  pending: false,
  error: null,
  data: null,
};

const shopReducer = {
  shop: createReducer(initialState, {
    ...getBaseReducer<ShopState>({
      type: shopConstants.SHOP_FETCH,
      onSuccess: setData(),
      onError: setError,
    }),
  }),
};

export default shopReducer;
