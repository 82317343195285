import TextField, { TextFieldParams } from 'components/common/forms/TextField';

import { INVALID_EMAIL_MESSAGE } from 'constants/messages';

export const EMAIL_REGEX_PATTERN = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/i;

const EmailField = (props: TextFieldParams) => (
  <TextField
    {...props}
    type="email"
    errorValidation={fieldState =>
      fieldState.error?.type === 'pattern' ? INVALID_EMAIL_MESSAGE : fieldState.error?.message
    }
  />
);

export default EmailField;
