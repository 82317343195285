import { Card, EmptyState } from '@shopify/polaris';
import { Helmet } from 'react-helmet';

interface StatusPageParams {
  code: string;
  message: string;
}

const StatusPage = ({ code, message }: StatusPageParams) => (
  <>
    <Helmet>
      <title>{code} | Captiv8</title>
    </Helmet>
    <Card sectioned>
      <EmptyState heading={code} image="">
        <p>{message}</p>
      </EmptyState>
    </Card>
  </>
);

export default StatusPage;
