import { FocusEvent } from 'react';
import { Error, TextField as ShopifyTextField } from '@shopify/polaris';

import { Controller, ControllerFieldState, useFormContext } from 'react-hook-form';

export interface TextFieldParams {
  name: string;
  label?: string;
  type?: 'text' | 'email' | 'number';
  disabled?: boolean;
  errorValidation?(fieldState: ControllerFieldState): boolean | Error | undefined;
  placeholder?: string;
  onFocus?(e: FocusEvent<any>): void;
}

const TextField = ({
  name,
  label,
  type = 'text',
  disabled,
  errorValidation = fieldState => fieldState.error?.message,
  placeholder,
  onFocus,
  ...props
}: TextFieldParams) => {
  const { setValue, control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value }, fieldState }) => (
        <ShopifyTextField
          type={type}
          label={label}
          name={name}
          disabled={disabled}
          onChange={nextValue => {
            setValue(name, nextValue, { shouldValidate: true });
          }}
          onFocus={onFocus as any}
          value={value}
          error={errorValidation(fieldState)}
          autoComplete="off"
          {...props}
        />
      )}
    />
  );
};

export default TextField;
