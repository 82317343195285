import { Banner, Button, TextStyle } from '@shopify/polaris';

const NoConnections = ({ secret, onReload }: { secret: string; onReload(): void }) => (
  <Banner
    title="You have not any connections"
    status="critical"
    action={{
      content: 'Reload',
      onAction: () => {
        onReload();
      },
    }}
  >
    <p>
      Please go to{' '}
      <Button plain external url="https://dev.captiv8.io/insights/settings/ecommerce">
        this page
      </Button>{' '}
      and add the secret &quot;<TextStyle variation="strong">{secret}</TextStyle>&quot;.
    </p>
  </Banner>
);

export default NoConnections;
