import usersApi from 'api/users';

import { createConstants } from 'utils/helpers';

export const usersConstants = createConstants('SEARCH_USERS', 'GET_USER_BY_ID');

export const usersActions = {
  search: (
    { query, limit, offset }: { query?: string; limit?: number; offset?: number } = {},
    apiSignal?: AbortSignal
  ) => ({
    type: usersConstants.SEARCH_USERS,
    payload: { query, limit, offset },
    meta: {
      apiCall: usersApi.search,
      apiSignal,
      apiOnlyLatest: true,
    },
  }),
  getUserById: (userId: number) => ({
    type: usersConstants.GET_USER_BY_ID,
    payload: { userId },
    meta: {
      apiCall: usersApi.getUserById,
    },
  }),
};
