import { Card } from '@shopify/polaris';

import ContentWrapper from 'components/common/ContentWrapper';
import StatelessComponent from 'components/common/StatelessComponent';
import Table, { ColumnParams } from 'components/common/Table';
import AttachDiscountForm from 'components/shop/forms/AttachDiscountForm';

import { DiscountCodesParams, EditAffiliateParams, shopActions } from 'ducks/shop';

import formatTitleCase from 'utils/formatters/formatTitleCase';

const Wrapper = ({ children }: { children: JSX.Element | null }) => (
  <Card.Section>{children}</Card.Section>
);

interface DiscountCodesResponse {
  discounts: DiscountCodesParams[];
}

const COLUMNS: ColumnParams<DiscountCodesParams>[] = [
  {
    id: 'title',
    title: 'Title',
  },
  {
    id: 'status',
    title: 'Status',
    renderValue: (status: string) => formatTitleCase(status),
  },
  {
    id: 'summary',
    title: 'Summary',
  },
  {
    id: 'commission',
    title: 'Commission',
    renderValue: (
      _: number,
      discount: DiscountCodesParams,
      {
        previewOnly,
        attachedDiscountCodes,
        attachDiscountToAffiliate,
        setCurrentAffiliate,
        setDiscountCodes,
      }: {
        previewOnly?: boolean;
        attachedDiscountCodes?: DiscountCodesParams[];
        attachDiscountToAffiliate?: EditAffiliateParams;
        setCurrentAffiliate?(affiliate: EditAffiliateParams): void;
        setDiscountCodes?(discountCodes: DiscountCodesParams[]): void;
      }
    ) => {
      const { shopify_discount_id: currentCodeId } = discount;
      const currentDiscount =
        attachedDiscountCodes?.find(
          ({ shopify_discount_id }) => shopify_discount_id === currentCodeId
        ) || discount;
      const { commission } = currentDiscount;

      if (
        !previewOnly &&
        attachedDiscountCodes &&
        setCurrentAffiliate &&
        setDiscountCodes &&
        attachDiscountToAffiliate
      ) {
        return (
          <AttachDiscountForm
            attachedDiscountCodes={attachedDiscountCodes}
            attachDiscountToAffiliate={attachDiscountToAffiliate}
            setCurrentAffiliate={setCurrentAffiliate}
            setDiscountCodes={setDiscountCodes}
            discount={currentDiscount}
          />
        );
      }

      return commission || 0;
    },
  },
].map(({ id, ...column }) => ({ id, prop: id, ...column }));

interface DiscountsParams {
  attachedDiscountCodes?: DiscountCodesParams[];
  attachDiscountToAffiliate?: EditAffiliateParams;
  withoutWrapper?: boolean;
  setCurrentAffiliate?(affiliate: EditAffiliateParams): void;
  setDiscountCodes?(discountCodes: DiscountCodesParams[]): void;
  previewOnly?: boolean;
}

const Discounts = ({
  attachedDiscountCodes,
  attachDiscountToAffiliate,
  withoutWrapper,
  setCurrentAffiliate,
  setDiscountCodes,
  previewOnly = false,
}: DiscountsParams) => {
  const columns = attachDiscountToAffiliate
    ? COLUMNS
    : COLUMNS.filter(({ id }) => id !== 'commission');

  return (
    <StatelessComponent<DiscountCodesResponse, { limit: number; offset: number }>
      action={shopActions.discountsFetch()}
      fetchOnMount
      statusWrapper={Wrapper}
    >
      {({ data }) => {
        const { discounts = [] } = data;

        return (
          <ContentWrapper noWrap={withoutWrapper} tagName={Wrapper}>
            <Table<DiscountCodesParams>
              columns={columns}
              rows={discounts}
              itemsLabel="discount codes"
              verticalAlign="middle"
              tableProps={{
                previewOnly,
                attachedDiscountCodes,
                attachDiscountToAffiliate,
                setCurrentAffiliate,
                setDiscountCodes,
              }}
            />
          </ContentWrapper>
        );
      }}
    </StatelessComponent>
  );
};

export default Discounts;
