import { configureStore } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import { routerMiddleware } from 'connected-react-router';

import history from 'routes/history';

import rootReducer from 'stores/reducers';

import authMiddleware from 'middleware/auth';

/** https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/redux/ */
const sentryReduxEnhancer = Sentry.createReduxEnhancer({});

const configureAppStore = configureStore({
  reducer: rootReducer(history),
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these field paths in all actions
        ignoredActionPaths: [
          'meta.apiCall',
          'meta.requestAction.meta.apiCall',
          'meta.apiSignal',
          'meta.requestAction.meta.apiSignal',
          'meta.apiCallError',
        ],
      },
    }).concat(routerMiddleware(history), authMiddleware),
  enhancers: [sentryReduxEnhancer],
});
export default configureAppStore;
