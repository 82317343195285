import { combineReducers } from '@reduxjs/toolkit';
import { connectRouter } from 'connected-react-router';
import { History } from 'history';

import authReducer from 'ducks/auth';
import shopReducer from 'ducks/shop';

const rootReducer = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    ...authReducer,
    ...shopReducer,
  });

export default rootReducer;
