export const REQUIRED_FIELD_MESSAGE = 'The field is required';
export const INVALID_EMAIL_MESSAGE = 'Invalid email address';

export const FETCH_ERROR_MESSAGE = "Sorry, we can't reach our server. Please, try again later.";

export const TYPE_ERROR_MESSAGE = 'Some error occurred';

export const API_ERROR_OVERRIDE_MESSAGES: {
  [key: number]: string;
} = {
  401: 'You are not authorized to perform this action.',
  404: 'Requested data not found.',
  413: "The size of file you're trying to upload is too large.",
  500: 'Some error happened on our servers.',
};

export const CONFIRM_NAVIGATION_MESSAGE = 'Are you sure? All unsaved changes will be lost';
export const CONFIRM_COPY_TO_ALL_MESSAGE = 'Are you sure? All existing data will be changed';
