import React, { ComponentType } from 'react';

export type ContentWrapperTagType = ComponentType | keyof JSX.IntrinsicElements;

interface ContentWrapperParams {
  children: JSX.Element | null;
  tagName?: any;
  noWrap?: boolean;
  wrapperProps?: Record<any, any>;
}

const ContentWrapper = ({
  noWrap = false,
  tagName = 'div',
  children,
  wrapperProps,
  ...props
}: ContentWrapperParams): JSX.Element | null => {
  if (noWrap) {
    return children;
  }

  return React.createElement(tagName, { ...wrapperProps, ...props }, children);
};

export default ContentWrapper;
