import { TextStyle, DataTable, TableData } from '@shopify/polaris';
import { get } from 'lodash';

import { hasLength } from 'utils/checkers';

export enum ColumnType {
  Text = 'text',
  Numeric = 'numeric',
}

export interface ColumnParams<DataColumnParams> {
  id: string | number;
  prop: string;
  title: string;
  type?: ColumnType;
  renderValue?: (
    value: any,
    row: DataColumnParams,
    tableProps?: any
  ) => TableData | null | undefined;
}

type VerticalAlign = 'top' | 'bottom' | 'middle' | 'baseline';

interface TableParams<DataColumnParams> {
  rows: DataColumnParams[];
  columns: ColumnParams<DataColumnParams>[];
  itemsLabel: string;
  tableProps?: any;
  verticalAlign?: VerticalAlign;
}

const Table = <DataColumnParams extends unknown>({
  rows,
  columns,
  itemsLabel,
  tableProps,
  verticalAlign = 'top',
}: TableParams<DataColumnParams>) => {
  const { columnContentTypes, headings } = columns.reduce(
    (
      acc: {
        columnContentTypes: ColumnType[];
        headings: string[];
      },
      column
    ) => {
      const { title, type } = column;

      acc.columnContentTypes.push(type || ColumnType.Text);
      acc.headings.push(title);

      return acc;
    },
    {
      columnContentTypes: [],
      headings: [],
    }
  );

  const formattedRows = rows.reduce((acc: TableData[][], row) => {
    const formattedRow = columns.map(column => {
      const { prop, renderValue } = column;
      let value = get(row, prop);

      if (renderValue) {
        value = renderValue(value, row, tableProps);
      }

      return value;
    });

    acc.push(formattedRow);

    return acc;
  }, []);

  return (
    <>
      <style>{`.Polaris-DataTable__Cell { white-space: normal }`}</style>
      {hasLength(rows) && (
        <DataTable
          columnContentTypes={columnContentTypes}
          headings={headings}
          rows={formattedRows}
          verticalAlign={verticalAlign}
          hoverable={false}
        />
      )}
      {!hasLength(rows) && <TextStyle variation="subdued">No {itemsLabel} yet</TextStyle>}
    </>
  );
};

export default Table;
