import { useCallback, useState } from 'react';
import { Card, Tabs } from '@shopify/polaris';

import Affiliates from 'components/shop/Affiliates';
import Discounts from 'components/shop/Discounts';
import NoConnections from 'components/shop/NoConnections';
import StatelessComponent from 'components/common/StatelessComponent';

import { shopActions, ShopParams } from 'ducks/shop';

import { hasLength } from 'utils/checkers';
import { createConstants } from 'utils/helpers';

const { AFFILIATES, DISCOUNTS } = createConstants('AFFILIATES', 'DISCOUNTS');

const TABS = [
  {
    id: AFFILIATES,
    content: 'Affiliates',
    component: Affiliates,
  },
  {
    id: DISCOUNTS,
    content: 'Discounts',
    component: Discounts,
  },
];

const Wrapper = ({ children }: { children: JSX.Element | null }) => (
  <Card>
    <Card.Section>{children}</Card.Section>
  </Card>
);

const ShopView = () => {
  const [selected, setSelected] = useState(0);

  const handleTabChange = useCallback(selectedTabIndex => setSelected(selectedTabIndex), []);

  return (
    <StatelessComponent<ShopParams>
      action={shopActions.shop()}
      fetchOnMount
      statusWrapper={Wrapper}
    >
      {({ data, onRequest }) => {
        const { shop: { secret = '' } = {}, connections = [] } = data;

        if (!hasLength(connections)) {
          return (
            <Wrapper>
              <NoConnections secret={secret} onReload={onRequest} />
            </Wrapper>
          );
        }

        const { component: Component } = TABS[selected];

        return (
          <Card>
            <Tabs tabs={TABS} selected={selected} onSelect={handleTabChange}>
              <Component />
            </Tabs>
          </Card>
        );
      }}
    </StatelessComponent>
  );
};

export default ShopView;
