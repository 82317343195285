import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { AppProvider } from '@shopify/polaris';
import '@shopify/polaris/build/esm/styles.css';
import enTranslations from '@shopify/polaris/locales/en.json';
import { ConnectedRouter } from 'connected-react-router';

import ErrorHandlingLayout from 'layouts/ErrorHandlingLayout';

import App from 'containers/App';

import history from 'routes/history';

import store from 'stores/store';

import reportWebVitals from 'reportWebVitals';

const theme = {
  colors: {
    surface: '#222C35',
    onSurface: '#222C35',
    interactive: '#457EDB',
    secondary: '#222C35',
    primary: '#457EDB',
    critical: '#DE3618',
    warning: '#EEC200',
    highlight: '#47C1BF',
    success: '#50B83C',
  },
};

ReactDOM.render(
  <StrictMode>
    <ErrorHandlingLayout>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <AppProvider theme={theme} i18n={enTranslations}>
            <App />
          </AppProvider>
        </ConnectedRouter>
      </Provider>
    </ErrorHandlingLayout>
  </StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
