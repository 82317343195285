export const authRedirects = {
  auth: '/auth',
};

export const redirectRedirects = {
  auth: '/redirect/auth',
};

export const platformUrl = '//captiv8.io';
export const platformRedirects = {
  campaigns: '/discovery/campaigns',
};
