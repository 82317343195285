import { useCallback, useRef, useState } from 'react';
import { Icon, Autocomplete, IconProps, ThumbnailProps, AvatarProps } from '@shopify/polaris';
import { SearchMinor } from '@shopify/polaris-icons';

import useApi from 'hooks/useApi';

import { ActionParams } from 'types/actionTypes';
import typedMemo from 'types/typedMemo';

interface OptionParams {
  value: string;
  label: string;
  media: React.ReactElement<IconProps | ThumbnailProps | AvatarProps>;
  [key: string]: any;
}

interface AutocompleteParams<T, I> {
  action: ActionParams;
  onSelect: (selectedItem: I) => void;
  handleMapOptions: (selectedItem: T) => OptionParams[];
  clearAfterSelect?: boolean;
}

interface PayloadParams {
  query: string;
}

const AutocompleteSelect = <RequestParams extends unknown, ItemParams>({
  action,
  onSelect,
  handleMapOptions,
  clearAfterSelect,
}: AutocompleteParams<RequestParams, ItemParams>) => {
  const lastAbortController = useRef<AbortController | null>(null);
  const { onRequest } = useApi<RequestParams, PayloadParams>({ action });
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState<OptionParams[]>([]);
  const [loading, setLoading] = useState(false);

  const updateText = useCallback(
    async value => {
      setInputValue(value);

      if (!loading) {
        setLoading(true);
      }

      if (lastAbortController.current) {
        lastAbortController.current.abort();
      }

      const currentAbortController = new AbortController();
      lastAbortController.current = currentAbortController;

      try {
        if (value === '') {
          setOptions([]);
          setLoading(false);
          return;
        }

        const response = await onRequest(
          {
            query: value,
          },
          currentAbortController.signal
        );

        if (currentAbortController.signal.aborted) {
          return;
        }

        const data = handleMapOptions(response);

        setOptions(data);
        setLoading(false);
      } catch (err) {
        if (currentAbortController.signal.aborted) {
          return;
        }

        setLoading(false);
      }
    },
    [loading, handleMapOptions, onRequest]
  );

  const updateSelection = useCallback(
    selected => {
      const [selectedItem] = selected.map((item: string) => {
        const matchedOption = options.find(option => option.value.match(item));
        return matchedOption;
      });
      setSelectedOptions(selected);

      setInputValue(clearAfterSelect ? '' : selected.label);

      onSelect(selectedItem);
    },
    [clearAfterSelect, options, onSelect]
  );

  const textField = (
    <Autocomplete.TextField
      onChange={updateText}
      label="Users"
      value={inputValue}
      prefix={<Icon source={SearchMinor} color="base" />}
      placeholder="Search"
      autoComplete="off"
    />
  );

  return (
    <>
      <Autocomplete
        options={options}
        selected={selectedOptions}
        textField={textField}
        onSelect={updateSelection}
        loading={loading}
        listTitle="Suggested Users"
      />
    </>
  );
};

export default typedMemo(AutocompleteSelect);
