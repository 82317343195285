import qs from 'qs';

import { __PROD__ } from 'constants/env';

import ApiError from 'utils/errors/ApiError';
import FetchError from 'utils/errors/FetchError';

import { ApiErrorResponsePayloadParams } from 'types/responseTypes';

const API_PREFIX = 'api/v1';
const API_HOST = __PROD__ ? `${window.location.protocol}//api.${window.location.host}` : '';
const API_URL = `${API_HOST}/${API_PREFIX}`;

export const makeRequest = async (
  url: string,
  options: RequestInit,
  bodyMethod: 'json' | undefined = 'json'
): Promise<Response> => {
  let savedResponse: Response | null = null;

  return fetch(url, options)
    .then(response => {
      savedResponse = response;
      // Force 413 errors to be ApiError, not FetchError,
      // as without this we will try to parse it, but 413
      // never contain json in response body
      // if (response.status === 413) {
      //   return {};
      // }

      return response[bodyMethod]();
    })
    .catch(responseOrError => {
      const response = savedResponse || responseOrError;
      const reason = responseOrError instanceof Error ? responseOrError : null;
      throw new FetchError(response, reason);
    })
    .then(jsonData => {
      if (!savedResponse?.ok) {
        throw new ApiError(savedResponse, jsonData as ApiErrorResponsePayloadParams);
      }

      return jsonData;
    });
};

export const injectUrlParameters = (
  urlTemplate: string,
  data: any,
  injectQueryParams?: boolean
) => {
  const queryParams = { ...data };
  let url = urlTemplate.replace(/\$\{(.*?)\}/g, (match, parameter) => {
    if (parameter.length) {
      delete queryParams[parameter];
      return encodeURIComponent(data[parameter]);
    }
    return encodeURIComponent(data);
  });
  if (injectQueryParams && Object.keys(queryParams).length > 0) {
    url = `${url}?${qs.stringify(queryParams)}`;
  }
  return url;
};

export const getJSON =
  (url: string, bodyMethod?: 'json') =>
  ({
    data,
    apiSignal: signal,
    authToken,
  }: {
    data: any;
    apiSignal?: AbortSignal;
    authToken?: string;
  }): Promise<Response> =>
    makeRequest(
      `${API_URL}${injectUrlParameters(url, data, true)}`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
        signal,
      },
      bodyMethod
    );

export const sendData =
  (type: 'POST' | 'PUT' | 'DELETE', url: string, bodyMethod?: 'json') =>
  ({
    data,
    authToken,
  }: {
    data: any;
    apiSignal?: AbortSignal;
    authToken?: string;
  }): Promise<Response> =>
    makeRequest(
      `${API_URL}${injectUrlParameters(url, data)}`,
      {
        method: type,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify(data),
        credentials: 'same-origin',
      },
      bodyMethod
    );
