import { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Form, FormLayout, Button } from '@shopify/polaris';

import { InfoParams } from 'components/shop/forms/AffiliatePreview';
import NumberField from 'components/common/forms/NumberField';

import {
  DiscountCodesParams,
  AttachAffiliateParams,
  EditAffiliateParams,
  shopActions,
} from 'ducks/shop';

import useApi from 'hooks/useApi';

import { REQUIRED_FIELD_MESSAGE } from 'constants/messages';

interface AttachDiscountFormParams {
  discount: DiscountCodesParams;
  setCurrentAffiliate(affiliate: EditAffiliateParams): void;
  setDiscountCodes(discountCodes: DiscountCodesParams[]): void;
  previewOnly?: boolean;
  attachedDiscountCodes: DiscountCodesParams[];
  attachDiscountToAffiliate: EditAffiliateParams;
}

const AttachDiscountForm = ({
  previewOnly,
  setCurrentAffiliate,
  setDiscountCodes,
  discount,
  attachedDiscountCodes,
  attachDiscountToAffiliate,
}: AttachDiscountFormParams) => {
  const [isSubmitActive, setIsSubmitActive] = useState(false);
  const onSuccessAttach = useCallback(
    async (response: InfoParams) => {
      const { affiliate, discounts } = response;
      setCurrentAffiliate?.(affiliate);
      setDiscountCodes?.(discounts);
    },
    [setCurrentAffiliate, setDiscountCodes]
  );

  const {
    pending: attachPending,
    onRequest: attachRequest,
    // error: attachError,
    // onResetError: onResetAttachError,
  } = useApi<InfoParams, AttachAffiliateParams>({
    action: shopActions.discountsAttach(),
    onSuccess: onSuccessAttach,
  });

  const onSuccessDetach = useCallback(
    async (response: InfoParams) => {
      const { affiliate, discounts } = response;
      setCurrentAffiliate?.(affiliate);
      setDiscountCodes?.(discounts);
    },
    [setCurrentAffiliate, setDiscountCodes]
  );

  const {
    pending: detachPending,
    onRequest: detachRequest,
    // error: detachError,
    // onResetError: onResetDetachError,
  } = useApi<InfoParams, AttachAffiliateParams>({
    action: shopActions.discountsDetach(),
    onSuccess: onSuccessDetach,
  });

  const { commission: initialCommission } = discount;
  const formMethods = useForm<{ commission: string }>({
    defaultValues: {
      commission: (initialCommission || 0).toString(),
    },
  });

  const { handleSubmit, formState, register, trigger } = formMethods;

  useEffect(() => {
    register('commission', { required: REQUIRED_FIELD_MESSAGE });

    trigger();
  }, [register, trigger]);

  useEffect(() => {
    setIsSubmitActive(formState.isValid);
  }, [formState]);

  const { shopify_discount_id: currentCodeId } = discount;
  const isAttached = attachedDiscountCodes?.some(
    ({ shopify_discount_id }) => shopify_discount_id === currentCodeId
  );

  const onSubmit = ({ commission }: { commission: number }) => {
    const submitAction = isAttached ? detachRequest : attachRequest;

    submitAction({
      affiliate: attachDiscountToAffiliate,
      discount: { ...discount, commission: +commission },
    });
  };

  return (
    <FormProvider {...formMethods}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormLayout>
          <FormLayout.Group>
            <NumberField
              name="commission"
              disabled={isAttached}
              min={0}
              max={100}
              placeholder={isAttached ? '' : 'Enter commission'}
              suffix="%"
              onFocus={e => {
                e.target.select();
              }}
            />
            <div style={{ width: 82 }}>
              <Button submit disabled={!isSubmitActive || attachPending || detachPending} fullWidth>
                {isAttached ? 'Detach' : 'Attach'}
              </Button>
            </div>
          </FormLayout.Group>
        </FormLayout>
      </Form>
    </FormProvider>
  );
};

export default AttachDiscountForm;
