import getStringWithSeparators from 'utils/formatters/getStringWithSeparators';

interface GetUserNameParams {
  first_name?: string | null;
  last_name?: string | null;
  email?: string | null;
  slug?: string | null;
  [key: string]: any;
}

const getUserName = ({ first_name = '', last_name = '', email, slug }: GetUserNameParams = {}) => {
  const firstName = first_name;
  const lastName = last_name;

  if (firstName?.trim() || lastName?.trim()) {
    return getStringWithSeparators([firstName, lastName], ' ');
  }

  return email || slug || 'No name';
};

export default getUserName;
