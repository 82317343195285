import { ExtraErrorData } from '@sentry/integrations';
import * as Sentry from '@sentry/react';
import { Helmet } from 'react-helmet';
import { BrowserRouter } from 'react-router-dom';

import routes from 'routes/main';

import { __ENV__, __PROD__, __REVISION__, __VERSION__ } from 'constants/env';

const App = () => {
  Sentry.init({
    environment: __ENV__,
    dsn: 'https://7bd25a74783e46408bd88cc3ecf19285@sentry.captiv8.io/9',
    integrations: [new ExtraErrorData({ depth: 10 })],
    normalizeDepth: 11,
    tracesSampleRate: 1.0,
    release: __VERSION__,
    debug: !__PROD__,
  });

  Sentry.configureScope(scope => {
    scope.setTag('release_version', __VERSION__);
    scope.setTag('code_version', __REVISION__);
    scope.setTag('host', window.location.host);
  });

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Captiv8 Shopify App</title>
      </Helmet>
      <BrowserRouter basename="/app">{routes}</BrowserRouter>
    </>
  );
};

export default App;
