import { useCallback, useEffect, useRef, useState } from 'react';
import { Avatar, Banner, Button, Modal } from '@shopify/polaris';

import AutocompleteSelect from 'components/common/AutocompleteSelect';
import AddAffiliateForm from 'components/shop/forms/AddAffiliateForm';

import { AffiliateParams, shopActions } from 'ducks/shop';
import { usersActions } from 'ducks/users';

import useApi from 'hooks/useApi';

import getUserName from 'utils/formatters/getUserName';

export interface UserParams {
  id: number;
  inf_user_id?: number;
  email?: string;
  first_name?: string;
  last_name?: string;
  slug: string;
  avatar?: string;
  is_exist: boolean;
}

export interface EditUserParams extends UserParams {
  inf_user_id: number;
  email: string;
  first_name: string;
  last_name: string;
  avatar: string;
}

interface RequestParams {
  inf_users: UserParams[];
  page_info: { limit: number; offset: number; total: number };
}

const AddAffiliateModal = ({ onFetch }: { onFetch: () => void }) => {
  const [isSubmitActive, setIsSubmitActive] = useState(false);
  const [active, setActive] = useState(false);
  const [selectedItem, setSelectedItem] = useState<UserParams | null>(null);
  const refFrom = useRef<HTMLFormElement>(null);

  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  const handleOpen = () => {
    if (isMounted.current) {
      setActive(true);
    }
  };

  const handleReset = () => {
    setSelectedItem(null);
    setIsSubmitActive(false);
  };

  const handleClose = useCallback(() => {
    if (isMounted.current) {
      handleReset();
      setActive(false);
    }
  }, []);

  const onSuccess = useCallback(async () => {
    if (isMounted.current) {
      handleClose();
      onFetch();
    }
  }, [handleClose, onFetch]);

  const { pending, error, onRequest, onResetError } = useApi<undefined, AffiliateParams>({
    action: shopActions.affiliatesAdd(),
    onSuccess,
  });

  const activator = (
    <Button primary onClick={handleOpen}>
      Add Affiliate
    </Button>
  );

  return (
    <Modal
      activator={activator}
      open={active}
      onClose={handleClose}
      title="Add Affiliate"
      primaryAction={{
        content: 'Add Affiliate',
        onAction: async () => {
          if (selectedItem) {
            try {
              refFrom.current?.submit();
            } catch (err) {
              console.error(err);
            }
          }
        },
        disabled: !isSubmitActive,
        loading: pending,
      }}
      {...(selectedItem
        ? {
            secondaryActions: [
              {
                content: 'Reset',
                onAction: async () => {
                  handleReset();
                },
              },
            ],
          }
        : {})}
    >
      {!!error && (
        <Modal.Section>
          <Banner
            title="Something went wrong"
            status="critical"
            onDismiss={() => {
              if (error) {
                onResetError();
              }
            }}
          >
            {error}
          </Banner>
        </Modal.Section>
      )}
      <Modal.Section>
        {!!selectedItem && (
          <AddAffiliateForm
            onAdd={onRequest}
            initialAffiliate={selectedItem}
            setIsSubmitActive={setIsSubmitActive}
            ref={refFrom}
          />
        )}
        {!selectedItem && (
          <AutocompleteSelect<RequestParams, UserParams>
            action={usersActions.search()}
            onSelect={item => setSelectedItem(item)}
            clearAfterSelect
            handleMapOptions={({ inf_users }) =>
              inf_users.map(({ slug, avatar, is_exist, ...user }: UserParams) => {
                const name = getUserName(user);

                return {
                  ...user,
                  slug,
                  avatar,
                  is_exist,
                  value: slug,
                  label: getUserName(user),
                  disabled: is_exist,
                  media: <Avatar source={avatar} name={name} customer size="small" />,
                };
              })
            }
          />
        )}
      </Modal.Section>
    </Modal>
  );
};

export default AddAffiliateModal;
