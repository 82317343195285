/* eslint-disable no-template-curly-in-string */

import { getJSON, sendData } from 'api/utils/requests';

export default {
  shop: getJSON('/shop'),

  affiliatesFetch: getJSON('/affiliates'),
  affiliatesAdd: sendData('POST', '/affiliates'),
  affiliatesEdit: sendData('PUT', '/affiliates'),
  affiliatesRemove: sendData('DELETE', '/affiliates'),
  affiliatesGetInfo: getJSON('/affiliates/${userId}'),

  discountsFetch: getJSON('/discounts'),
  discountsAttach: sendData('POST', '/discounts/attach'),
  discountsDetach: sendData('POST', '/discounts/detach'),
};
