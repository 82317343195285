import TextField, { TextFieldParams } from 'components/common/forms/TextField';

interface NumberFieldParams extends TextFieldParams {
  min?: number;
  max?: number;
  prefix?: string;
  suffix?: string;
}

const NumberField = (props: NumberFieldParams) => <TextField {...props} type="number" />;

export default NumberField;
