import StatusPage from 'components/errors/StatusPage';

const StatusPage500 = () => (
  <StatusPage
    code="500"
    message="Sorry, we are having technical problems now. Please, come back later."
  />
);

export default StatusPage500;
