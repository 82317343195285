import { ReactNode } from 'react';
import flatten from 'lodash/flatten';

import { __PROD__ } from 'constants/env';
import { TYPE_ERROR_MESSAGE } from 'constants/messages';

import formatNodeList from 'utils/formatters/formatNodeList';
import FetchError from 'utils/errors/FetchError';

import { ApiErrorMessagesType } from 'types/responseTypes';

const getParsedErrorMessage = (message: any): string | null => {
  let newMessage = message;

  // Handle the case where the backend sends single arrays instead of strings
  if (Array.isArray(message) && message.length === 1) {
    [newMessage] = message;
  }

  if (typeof newMessage !== 'string') {
    return null;
  }

  if (newMessage !== message) {
    return newMessage;
  }

  return null;
};

export const getErrorMessage = (
  messages: boolean | ApiErrorMessagesType | string | FetchError | TypeError | Error,
  separator = '\r\n'
): ReactNode => {
  if (typeof messages === 'boolean' || !messages) {
    return TYPE_ERROR_MESSAGE;
  }

  if (typeof messages === 'string') {
    return messages;
  }

  if (messages instanceof FetchError) {
    const {
      reason: { message },
    } = messages as { reason: Error };

    return message;
  }

  if (messages instanceof TypeError || messages instanceof Error) {
    const { message } = messages;

    return !__PROD__ ? message : TYPE_ERROR_MESSAGE;
  }

  const values = messages && Object.values(messages);

  const flattenErrors = flatten(values).map(message => getParsedErrorMessage(message) || message);

  const onlyStrings = !!flattenErrors.filter(message => typeof message === 'string').length;

  if (onlyStrings) {
    return flattenErrors.join(separator);
  }

  return formatNodeList(flattenErrors);
};

export default getErrorMessage;
