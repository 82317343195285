import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Card, SkeletonBodyText } from '@shopify/polaris';
import { isEmpty } from 'lodash';

import ErrorBanner from 'components/common/ErrorBanner';

import { authActions, ShopifyFinalizeParams } from 'ducks/auth';

import useApi from 'hooks/useApi';
import useQuery from 'hooks/useQuery';

const Wrapper = ({ children }: { children: JSX.Element | null }) => (
  <Card>
    <Card.Section>{children}</Card.Section>
  </Card>
);

const RedirectAuthView = () => {
  const history = useHistory();
  const query: ShopifyFinalizeParams = useQuery();

  const memoizedOnSuccess = useCallback(async () => {
    history.push('/');
  }, [history]);

  const { error } = useApi({
    action: authActions.finalize(query),
    onSuccess: memoizedOnSuccess,
    fetchOnMount: !isEmpty(query),
  });

  if (error) {
    return (
      <Wrapper>
        <ErrorBanner error={error} />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <SkeletonBodyText />
    </Wrapper>
  );
};

export default RedirectAuthView;
