const storage = window.sessionStorage;

export const setItem = (path, item) => {
  storage.setItem(path, JSON.stringify(item));
};

export const getItem = path => JSON.parse(storage.getItem(path));

export const removeItem = path => {
  storage.removeItem(path);
};
